<template>
    <div class="container">
        <video-player
                ref="videoPlayer"
                :options="videoOptions"
                :playsinline="true"
                class="video-css"
        ></video-player>
        <div class="play" @click="playVideo()">
            <img src="../assets/img/play.png" alt="">
        </div>
    </div>
</template>

<script>
    import videoPlayer from './videoPlayer.vue'
    export default {
        name: 'videoComponent',
        components:{videoPlayer},
        data(){
            return{
                videoOptions:{
                    //controls配置项决定是否显示默认控件
                    // controls:true,
                    autoplay:true,
                    //fluid配置项根据外层css样式大小，自动填充宽高
                    fluid:true,
                    loop: true,
                    //sources配置项配置视频播放源
                    sources:[
                        {
                            //播放源
                            src:'https://img.keywedding.cn/mys%26music.MP4',
                            //视频类型
                            type:"video/mp4"
                        }
                    ]
                }
            }
        },
        methods:{
            playVideo(){
                this.$refs.videoPlayer.player.play()
            }
        }
    }
</script>

<style scoped lang="less">
    .container{
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }
    /* 视频宽高将由此样式调整 */
    .video-css{
        width: 100vh;
        height: auto;
    }
    .play{
        position: absolute;
        top: 40%;
        left: 50%;
        z-index: 2;
        opacity: 0.6;
        transform: translateX(-50%);
        width: 20vw;
        height: 20vw;
        border: 0.6vw solid #fff;
        border-radius: 50%;
        img{
            width: 10vw;
            height: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-35%,-50%);
        }
    }
</style>
