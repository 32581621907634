<template>
    <div class="headTop">
        <div class="headFl" v-if="isShowMenu">
            <ul>
                <li>
                    <a href="/">
                        <span>-</span>首页
                    </a>
                </li>
                <li>
                    <a href="/#/about">
                        <span>-</span>关于蜜钥匙
                    </a>
                </li>
            </ul>
        </div>
        <div class="headLogo">
            <img src="../assets/img/logo.png" alt="">
        </div>
        <div class="headMenu">
            <i class="iconfont el-icon-menu menuShow" v-if="!isShowMenu" @click="menuShow"></i>
            <i class="iconfont el-icon-close menuHide" v-if="isShowMenu" @click="menuHide"></i>
        </div>
    </div>
</template>
<script>
    import $ from 'jquery'
    export default{
        name: 'headTop',
        data(){
            return{
                isShowMenu:false
            }
        },
        created(){
            if($(window).width() > 1200){
                this.isShowMenu = true;
            } else{
                this.isShowMenu = false;
            }
        },
        mounted(){

        },
        methods:{
            menuShow(){
                this.isShowMenu = true;
                $('.headTop').css('background','rgba(0,0,0,1)')
            },
            menuHide(){
                this.isShowMenu = false;
                $('.headTop').css('background','rgba(0,0,0,0.75)')
            }
        }
    }
</script>
<style lang="less">
    @media screen and (min-width: 1200px){
        .headTop{
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 2;
            height: 44px;
            background: rgba(0,0,0,0.75);
            .headFl{
                position: absolute;
                left: 20px;
                top: 0;
                ul{
                    li{
                        float: left;
                        padding: 0 20px;
                        line-height: 44px;
                        position: relative;
                        color: #fff;
                        font-size: 12px;
                        font-weight: 300;
                        cursor: pointer;
                        a{
                            color: #fff;
                            display: block;
                        }
                        &:after{
                            content: '';
                            position: absolute;
                            height: 1px;
                            width: calc(100% - 20px);
                            background: #00bebe;
                            left: 50%;
                            top: 80%;
                            transform: translate(-50%);
                            display: none;
                        }
                        &:hover:after{
                            display: block;
                        }
                        span{
                            display: none;
                        }
                    }
                }
            }
            .headLogo{
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%);
                img{
                    height: 30px;
                    vertical-align: top;
                }
            }
            .headMenu{
                display: none;
            }
        }
    }
    @media screen and (max-width: 1200px){
        .headTop{
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 3;
            height: 9vw;
            background: rgba(0,0,0,0.75);
            .headFl{
                position: absolute;
                top: 9vw;
                left: 0;
                width: 100%;
                height: calc(100vh - 9vw);
                background: #000;
                z-index: 3;
                border-top: 1px solid rgba(255,255,255,0.1);
                ul{
                    padding-top: 2vw;
                    li{
                        height: 9vw;
                        line-height: 9vw;
                        padding-left: 4vw;
                        font-size: 4.5vw;
                        color: #fff;
                        a{
                            color: #fff;
                            display: block;
                        }
                        span{
                            display: inline-block;
                            margin-right: 2vw;
                        }
                    }
                }
            }
            .headLogo{
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%);
                img{
                    height: 6.5vw;
                    vertical-align: top;
                }
            }
            .headMenu{
                display: block;
                position: absolute;
                left: 4vw;
                top: 50%;
                transform: translateY(-50%);
                .iconfont{
                    font-size: 5vw;
                    color: #fff;
                }
            }
        }
    }
</style>
