<template>
    <div class="index">
        <div class="backVideo">
            <video poster="../../assets/img/videoPc.jpg" autoplay loop muted playsinline preload>
                <source src="https://img.keywedding.cn/video.mp4" type="video/mp4">
            </video>
        </div>
        <div class="backImg">
            <video-component></video-component>
        </div>
        <headTop/>
        <div class="indexFix" v-if="isShowFix" :style="{bottom:pageYOffset + 'px'}">
            <div class="indexFixMain clear">
                <div class="indexFixFl">
                    <img src="../../assets/img/png01.png" alt="">
                </div>
                <div class="indexFixCtn">
                    <h1>扫一扫蜜钥匙小程序</h1>
                    <p>让你轻松无忧一站式备婚</p>
                </div>
                <div class="indexFixFr">
                    <img src="../../assets/img/ewm.png" alt="">
                </div>
                <div class="indexClose" @click="hideFix">
                    <i class="el-icon-close"></i>
                </div>
            </div>
        </div>
        <footBottom/>
    </div>
</template>

<script>
    import headTop from '@/components/headTop'
    import footBottom from '@/components/footBottom'
    import videoComponent from '@/components/videoComponent'
    import $ from 'jquery'
    export default {
        name: 'index',
        components:{
            headTop,
            footBottom,
            videoComponent
        },
        data() {
            return{
                isShowFix:true,
                pageYOffset:-210
            }
        },
        created(){

        },
        mounted(){
            window.addEventListener('scroll', this.handleScroll)
        },
        beforeDestroy(){
            window.removeEventListener('scroll', this.handleScroll)
        },
        methods:{
            hideFix(){
                this.isShowFix = false;
            },
            handleScroll() {
                this.pageYOffset = window.pageYOffset
            }
        }
    }
</script>
<style lang="less">
    @media screen and (min-width: 1200px){
        .index{
            height: 100%;
        }
        .backImg{
            display: none;
        }
        .backVideo{
            width: 100%;
            height: 100%;
            video{
                width: 100%;
                height: 100%;
                object-fit: cover;
                background-size: cover;
                background-position: 50%;
                background-repeat: no-repeat;
                vertical-align: top;
            }
        }
        .indexFix{
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            height: 130px;
            z-index: 1001;
            background-color: rgba(255,255,255,0.6);
            white-space: nowrap;
            box-shadow: 0 -3px 80px rgba(0,0,0,.08);
            transition: 0.1s;
            .indexFixMain{
                text-align: center;
                .indexFixFl{
                    width: 190px;
                    display: inline-block;
                    position: relative;
                    bottom: 77px;
                    vertical-align: top;
                    img{
                        width: 100%;
                        height: auto;
                    }
                }
                .indexFixCtn{
                    margin-left: 35px;
                    display: inline-block;
                    line-height: 1;
                    vertical-align: top;
                    text-align: left;
                    h1{
                        margin-top: 40px;
                        color: #101d37;
                        font-size: 20px;
                        margin-bottom: 12px;
                        font-weight: 700;
                    }
                    p{
                        color: #9399a5;
                        font-size: 13px;
                        margin-top: 8px;
                    }
                }
                .indexFixFr{
                    display: inline-block;
                    vertical-align: top;
                    width: 88px;
                    margin-top: 22px;
                    margin-left: 63px;
                    img{
                        width: 100%;
                        height: auto;
                    }
                }
                .indexClose{
                    display: inline-block;
                    vertical-align: top;
                    margin-top: 22px;
                    width: 15px;
                    margin-left: 41px;
                    cursor: pointer;
                    i{
                        font-size: 16px;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1200px){
        .backVideo{
            display: none;
        }
        .backImg{
            display: block;
            height: 100vh;
            width: 100%;
            position: relative;
            overflow: hidden;
            img{
                object-fit: cover;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100vh;
                z-index: 2;
            }
            .container{
                overflow: hidden;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100vh;
                z-index: 2;
                .videoBox{
                    height: 100vh;
                    .video-js{
                        height: 100vh;
                        .vjs-tech{
                            position: absolute;
                            top: 0;
                            left: 50%;
                            width: auto;
                            height: 100vh;
                            transform: translateX(-50%);
                        }
                        .vjs-big-play-button{
                            display: none;
                        }
                    }
                }
            }
        }

        .indexFix{
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            height: 25vw;
            z-index: 1001;
            background-color: rgba(255,255,255,0.6);
            white-space: nowrap;
            box-shadow: 0 -3px 80px rgba(0,0,0,.08);
            transition: 0.1s;
            .indexFixMain{
                text-align: center;
                position: relative;
                .indexFixFl{
                    display: inline-block;
                    position: relative;
                    bottom: 7vw;
                    vertical-align: top;
                    img{
                        height: 32vw;
                    }
                }
                .indexFixCtn{
                    margin-left: 3vw;
                    display: inline-block;
                    line-height: 1;
                    vertical-align: top;
                    text-align: left;
                    h1{
                        margin-top: 8vw;
                        color: #101d37;
                        font-size: 4vw;
                        font-weight: 700;
                    }
                    p{
                        color: #9399a5;
                        font-size: 3vw;
                        margin-top: 2vw;
                    }
                }
                .indexFixFr{
                    display: inline-block;
                    vertical-align: top;
                    width: 14vw;
                    margin-top: 22px;
                    margin-left: 3vw;
                    img{
                        width: 100%;
                        height: auto;
                    }
                }
                .indexClose{
                    position: absolute;
                    top: 2vw;
                    right: 1vw;
                    i{
                        font-size: 16px;
                    }
                }
            }
        }
    }

</style>
