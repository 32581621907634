import Vue from 'vue'
import VueRouter from 'vue-router'
import index from "../views/index/index";

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'index',
    component: index
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/about/index.vue')
  }
];

const router = new VueRouter({
  routes
});

export default router
